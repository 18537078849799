@value constants: "../../styles/constants.css";
@value small-break, large-break from constants;

.backdrop {
  z-index:1101 !important;
  display: block !important;
}

.container {
  padding: 1em 4em;
  height: 100%;
  flex: auto;
  overflow-y: scroll;
}

.footer {
  padding: 0em 4em;
  height: 4em;
}

.header {
  text-align: right;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
}

.title {
  position: fixed;
  padding-top: 1em;
  padding-left: 1em;
}

@media only screen and (min-width: small-break) {
  .paper {
    margin: 1em auto;
    height: 90%;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: large-break) {
  .paper {
    display: flex;
    margin: 1em auto;
    max-width: 40em;
    height: 95%;
  }
}