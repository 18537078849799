@value constants: "../../styles/constants.css";
@value darkestgrey, small-break, medium-break, large-break from constants;

.app {
    height: 100%
}

.appRetro {
  background-image: url("../../images/retro-background.gif") !important;
  background-repeat: repeat;
}

.loading {
  text-align: center;
  font-weight: bolder;
}

.loadingIcon {
  display: block;
  width: 20em;
  margin-left: auto;
  margin-right: auto;
}

.main {
  min-height: 100vh;
}

@media only screen and (min-width: small-break) {
  .main {
    padding-top: 5em;
    padding-bottom: 12em;
  }
}

@media only screen and (min-width: medium-break) {
  .main {
    padding-top: 5em;
    padding-bottom: 12em;
  }
}

@media only screen and (min-width: large-break) {
  .main {
    padding-top: 6em;
    padding-bottom: 12em;
  }
}