@value constants: "../../styles/constants.css";
@value small-break, medium-break, large-break from constants;

.drawer {
  display: block;
}

.link {
  color: white;
  font-weight: bolder;
}

.hamburger {
  color: white;
}

.header {
    font-weight: bold;
    color: white;
    text-align: center;
}

.hidden {
  display: none;
}

.pointer {
  cursor: pointer;
}

.title {
  flex-grow: 1;
  color: white;
}

@media only screen and (min-width: small-break) {
  .header {
    font-size: 18px;
    padding: 1em 0;
  }

  .headerContainer {
    background: url("../../images/banner.jpg");
    background-size: cover;
    background-position: center center;
  }

  .switch {
    display: none;
  }
}

@media only screen and (min-width: medium-break) {
  .header {
    font-size: xx-large;
    padding-top: 0em;
  }

  .headerContainer {
    background: url("../../images/banner.jpg");
    background-size: cover;
    background-position: center center;
  }

  .switch {
    display: none;
  }
}

@media only screen and (min-width: large-break) {
  .header {
    font-size: xx-large;
    padding-top: 0em;
  }

  .headerContainer {
    background: url("../../images/banner.jpg");
    background-size: cover;
    background-position: center center;
  }

  .switch {
    display: block;
  }
}