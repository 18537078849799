@value constants: "../../styles/constants.css";
@value small-break, medium-break, large-break from constants;

.drawer {
  display: block;
}

.hidden {
  display: none;
}

.pointer {
  cursor: pointer;
}

.header {
  background: url("../../images/banner.jpg");
  background-size: cover;
  background-position: center center;
}

.headerContainer {
  height: 100vh;
  min-height: -webkit-fill-available;
  overflow-x: hidden;
}

.title {
  font-family: 'MajorMonoDisplay' !important;
  color: white;
}

@media only screen and (min-width: small-break) {
  .headerContainer {
    background: url("../../images/nbp_logo_banner_mobile.png");
    background-size: 180%;
    background-position: center 40%;
    background-repeat: no-repeat;
    background-color: #141032;
  }

  .player {
    margin-bottom: 4em !important;
    text-align: center;
  }

  .switch {
    display: none;
  }
}

@media only screen and (min-width: medium-break) {
  .headerContainer {
    background: url("../../images/nbp_logo_banner.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #141032;
  }

  .player {
    margin-bottom: 2em !important;
    text-align: center;
  }

  .switch {
    display: none;
  }
}

@media only screen and (min-width: large-break) {
  .headerContainer {
    background: url("../../images/nbp_logo_banner.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #141032;
  }

  .player {
    margin-bottom: 2em !important;
    text-align: center;
  }

  .switch {
    display: block;
  }
}