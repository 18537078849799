body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'MajorMonoDisplay';
  src: url('./fonts/MajorMonoDisplay-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
