@value constants: "../../styles/constants.css";
@value small-break, medium-break, large-break from constants;

.author {
    text-align: center;
}

.card {
    margin: auto;
    background: rgba(0, 0, 0, 0.6);
    padding: 1em;
}

.tags {
    color: white;
    font-size: .8em;
    text-align: center;
}

@media only screen and (min-width: small-break) {
  .author {
    display: none;
  }

  .card {
    width: 90%;
    font-size:small;
  }

  .tags {
    display: none;
  }
}

@media only screen and (min-width: medium-break) {
  .author {
    display: block;
  }

  .card {
    width: 90%;
    font-size:small;
  }

  .tags {
    display: block;
  }
}

@media only screen and (min-width: large-break) {
  .author {
    display: block;
  }

  .card {
    width: 50%;
    font-size: medium;
  }

  .tags {
    display: block;
  }
}