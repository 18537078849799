@value constants: "../../styles/constants.css";
@value small-break, large-break from constants;

.button {
  margin-left: auto !important;
}

.wideButton {
  margin-left: 10%!important;
  width: 80%;
}

.container {
  margin-top: 1em;
  margin-bottom: 1em;
}

@media only screen and (min-width: small-break) {
  .actions {
    margin-bottom: 1em;
  }

  .card {
    height: 95%;
  }

  .browser {
    margin-left: 1em;
    margin-right: 1em;
  }
}

@media only screen and (min-width: large-break) {
  .actionArea {
    height: 75%;
    overflow: hidden;
  }

  .browser {
    margin-left: 20em;
    margin-right: 20em;
  }

  .card {
    height: 300px;
    margin: 1em 1em;
  }  
}