.container {
    width: 250px;
}

.link {
    text-decoration: none !important;
}

@media (prefers-color-scheme: light) {
  .link {
    color: black;
  }
}

@media (prefers-color-scheme: dark) {
  .link {
    color: white;
  }
}