.left {
    text-align: left;
}

.table {
    min-width: 650px;
}

.container {
    width: 90% !important;
    margin: auto;
}