.chartContainer {
    overflow-x: auto;
    width: 100%;
    margin: auto auto;
}

.chart {
    margin: 2em auto;
}

.label {
    text-align: left;
    background-color: rgba(0,0,0,.8);
}